.thirdLineVehicule {
    margin: auto;
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 1rem;
}

.vehiculesFiles {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding: 1rem;
}

.vehiculeLabel {
    font-size: 1rem;
    text-align: center;
    width: 100%;
    padding: 1rem;
}

.VehiculeCard {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    padding: 1rem;
}

.vehiculeButton {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding: 1rem;
}