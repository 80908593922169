.service-row {
    background-color: #f1f1f1;
    color: #333; 
  }

  #dark .service-row {
    background-color: #2C2C2C;
    color: white;
  }

  .divButton {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .show_services {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: #f0f0f0;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    margin: 0.25rem;
    transition: background 0.3s ease;
    justify-content: center;
    font-size: .9rem;
  
    &:hover {
      background: #e0e0e0;
    }
  
    svg { /* Style for the Arrow icons */
      margin-left: 0.5rem;
    }

    #dark & {
      background: #2C2C2C;
      color: white;
      &:hover {
        background: #3C3C3C;
      }
    }
  }
  
  .add__service,
  .delete__etablissement {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    font-size: 0.9rem;
    margin: 0.25rem;
    transition: background 0.3s ease, color 0.3s ease;
  }

  
  .add__service {
    background: #007bff; /* Blue background for the add button */
    color: #fff; /* White text */
  
    &:hover {
      background: #0056b3; /* Darker blue on hover */
    }

    #dark & {
      background: #2C2C2C;
      color: white;
      &:hover {
        background: #3C3C3C;
      }
    }
  }
  
  .delete__etablissement {
    background: #ff0000; /* Red background for the delete button */
    color: #fff; /* White text */
  
    &:hover {
      background: #cc0000; /* Darker red on hover */
    }

    #dark & {
      background: #2C2C2C;
      color: white;
      &:hover {
        background: #3C3C3C;
      }
    }
  }

  .suggestions-list {
    padding: 0;
    margin: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    border-radius: 5px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .suggestions-list-item {
    padding: 10px;
    cursor: pointer;
    font-size: 1rem;
    color: #333;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 0.3s ease;
    font-weight: 500; /* Ajustez selon vos préférences */
    -webkit-font-smoothing: antialiased; /* Pour lisser les polices sur Chrome et Safari */
    -moz-osx-font-smoothing: grayscale; /* Pour lisser les polices sur Firefox */
    text-rendering: optimizeLegibility; /* Pour optimiser la lisibilité du texte */
  }
  
  .suggestions-list-item:hover {
    background-color: #f9f9f9;
  }
  
  .suggestions-list-item:last-child {
    border-bottom: none;
  }

  .addServiceModal {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  
  