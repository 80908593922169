.terms-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
    line-height: 1.6;
    color: #333;
  
    h1 {
      font-size: 2.5rem;
      margin-bottom: 20px;
      color: #1a2b42;
      text-align: center;
    }
  
    h2 {
      font-size: 1.8rem;
      margin-top: 40px;
      margin-bottom: 20px;
      color: #1a2b42;
    }
  
    p {
      font-size: 1rem;
      margin-bottom: 20px;
    }
  
    ul {
      margin-left: 20px;
      list-style-type: disc;
  
      li {
        margin-bottom: 10px;
      }
    }
  
    a {
      color: #e7961f;
      text-decoration: none;
  
      &:hover {
        text-decoration: underline;
      }
    }
  
    /* Responsive */
    @media screen and (max-width: 768px) {
      h1 {
        font-size: 2rem;
      }
  
      h2 {
        font-size: 1.5rem;
      }
    }
  }
  