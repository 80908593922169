.login-container {
  display: flex;
  justify-content: space-between;
  background-color: #2c3e50; // Replace with your background color
  height: 100vh;
  background-image: url('../../images/loginLight.png'); /* Chemin vers votre image de fond */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35%;
    background: #ffffff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .mfa-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #2C2C2C;
      font-size: 1rem;
  
      h2 {
        font-size: 1.75rem;
        margin-bottom: 10px;
        color: #2C2C2C;
        text-align: center;
        font-weight: bold;
      }
  
      p {
        font-size: 1rem;
        margin-bottom: 20px;
        color: #555;
        text-align: center;
        max-width: 300px;
      }
  
      input {
        width: 100%;
        max-width: 300px;
        padding: 12px 15px;
        margin-bottom: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 1rem;
        text-align: center;
        letter-spacing: 2px;
        transition: border-color 0.3s ease, box-shadow 0.3s ease;
  
        &:focus {
          border-color: #e7961f;
          box-shadow: 0 0 5px rgba(231, 150, 31, 0.5);
          outline: none;
        }
  
        &.error {
          border-color: #ff4d4f;
        }
      }
  
      .error-message {
        color: #ff4d4f;
        font-size: 0.85rem;
        margin-bottom: 15px;
        text-align: center;
      }
  
      .buttonLogin {
        width: 100%;
        max-width: 300px;
        padding: 12px;
        background-image: linear-gradient(to right, #4CAF50 30%, #81C784 80%);
        color: #ffffff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1rem;
        font-weight: bold;
        transition: background-image 0.3s ease, transform 0.2s ease;
  
        &:hover {
          background-image: linear-gradient(to right, #43A047 30%, #66BB6A 80%);
          transform: translateY(-2px);
        }
  
        &:active {
          transform: translateY(0);
        }
  
        &:disabled {
          background-color: #cccccc;
          cursor: not-allowed;
        }
      }
  
      .social-login {
        display: none; /* Masqué dans le formulaire MFA, mais gardé pour la cohérence */
      }
    }

    h1 {
      margin-bottom: 20px;
      background-image: linear-gradient(to right, rgb(120, 119, 119) 30%, #e7961f 80%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent; // Fallback color
      display: inline-block; // Nécessaire pour que background-clip fonctionne
      -webkit-text-fill-color: transparent; // Spécifique à Webkit pour rendre le texte transparent
    }
    

    .login-form {
      display: flex;
      flex-direction: column;
      color: #2C2C2C;
      font-size: 1rem;

      input {
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        color: #333;
      }

      .remember-forgot {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        font-size: 1rem;

        .labelForm {
          color: red;
        }

        label {
          color: #e7961f;
          input {
            margin-right: 5px;
          }
        }

        a {
          color: #e7961f;
          text-decoration: none;
        }
      }

      .buttonLogin {
        padding: 10px;
        background-image: linear-gradient(to right, rgb(204, 204, 204) 30%, #e7961f 80%);
        color: #ffffff;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          transform: translateY(-2px); /* Effet de léger soulèvement au survol */
        }
      }

      .signup-link {
        margin-top: 20px;
        display: block;
        color: #e7961f;
        text-decoration: none;
        text-align: center;
      }

      .social-login {
        // Style for social login icons
      }
    }
  }
}
input.error {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}


@media screen and (max-width: 960px) {
  .login-container {
    flex-direction: column;
    height: 100vh;

    .stats-panel {
      display: none;
    }

    .form-container {
      width: 100%;
      height: 100%;

      .mfa-form {
        width: 90%;
        max-width: 400px;
        padding: 20px;
  
        h2 {
          font-size: 1.5rem;
        }
  
        p {
          font-size: 0.95rem;
        }
  
        input {
          max-width: 100%;
        }
  
        .buttonLogin {
          max-width: 100%;
        }
      }

      .remember-forgot {
        flex-direction: column;
        align-items: center;
      }
    }
  }
  
}

.password-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input {
  width: 100%;
  /* autres styles pour votre input */
}

.password-toggle {
  position: absolute;
  right: 10px;
  border: none;
  cursor: pointer;
  background: none;
}

#dark .login-container {
  background-image: url('../../images/loginDark.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#dark .form-container {
  background: #181818;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  .mfa-form {
    h2 {
      color: #ffffff;
    }

    p {
      color: #cccccc;
    }

    input {
      border: 1px solid #555;

      &:focus {
        border-color: #e7961f;
        box-shadow: 0 0 5px rgba(231, 150, 31, 0.5);
      }

      &.error {
        border-color: #ff4d4f;
      }
    }

    .error-message {
      color: #ff4d4f;
    }

    .remember-forgot {
      label {
        color: #e7961f;
      }
    }
  }

  h1 {
    background-image: linear-gradient(to right, rgb(120, 119, 119) 30%, #e7961f 80%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent; // Fallback color
    display: inline-block; // Nécessaire pour que background-clip fonctionne
    -webkit-text-fill-color: transparent; // Spécifique à Webkit pour rendre le texte transparent
  }

  .login-form {
    input {
      border: 1px solid #ddd;
    }

    .buttonLogin {
      background-image: linear-gradient(to right, rgb(204, 204, 204) 30%, #e7961f 80%);
      color: #ffffff;
    }

    .signup-link {
      color: #e7961f;
    }
  }
}