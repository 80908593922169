.managementData {
    align-items: center;
    text-align: center;
    width: 100%;
    min-height: 100vh;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
}
.managementDataTitle {
  font-size: 1.5rem;
  padding: 1.5rem 0;
}
.dashboardMD {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  padding: 10px;
  border: 2px solid rgba(54, 162, 235, 0.2);
  border-radius: 5px;
  font-size: .8rem;
  color: #333;
  box-shadow: 0 2px 5px rgba(187, 144, 144, 0.1);
  transition: box-shadow 0.3s;
  cursor: pointer;
  margin-bottom: 1rem;
  width: calc(32.333% - 20px);
  align-items: center;
  text-align: center;
  margin: auto;
  
  @media (max-width: 768px) {
    width: 100%;
  }
}

.star {
  color: #ccc;
}
.star.filled {
  color: #f39c12;
}

.cards-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.card p {
  font-size: 1.1rem;
}

.reason {
  border-left: 5px solid #a8a8a8; /* Une bordure élégante pour les raisons */
  width: 100%;
}

.vehicle {
  border-left: 5px solid #d4d4d4; /* Une bordure plus douce pour les véhicules */
}

.TotalRetire {
  border-left: 5px solid #444; /* Une bordure sombre pour le total */
  width: 100%;
}

.TotalVeh {
  width: 100%;
}

.card h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.card p {
  font-size: 1em;
}

.section-title {
  font-size: 1.5em;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
}


.TP {
  background-color: rgba(158, 135, 105, 0.2);
}
.ABS {
  background-color: rgba(255, 99, 132, 0.2);
}
.ABS:hover {
  background-color: rgba(255, 99, 132, 0.4);
}

.TO {
  background-color: rgba(54, 162, 235, 0.2);
}
.TO:hover {
  background-color: rgba(54, 162, 235, 0.4);
}

.TF {
  background-color: rgba(255, 206, 86, 0.2);
}
.TF:hover {
  background-color: rgba(255, 206, 86, 0.4);
}

.AT {
  background-color: rgba(75, 192, 192, 0.2);
}

.AT:hover {
  background-color: rgba(75, 192, 192, 0.4);
}
.card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.AA {
  background-color: rgba(153, 102, 255, 0.2);
}
.AA:hover {
  background-color: rgba(153, 102, 255, 0.4);
}

.GB {
  background-color: rgba(255, 159, 64, 0.2);
}
.GB:hover {
  background-color: rgba(255, 160, 64, 0.586);
}
.PAR {
  background-color: rgba(190, 147, 147, 0.984);
}
.PAR:hover {
  background-color: rgba(157, 120, 120, 0.714);
}
.AM {
  background-color: rgba(255, 151, 99, 0.832);
}
.AM:hover {
  background-color: rgba(255, 128, 99, 0.876);
}
.cards-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
  }

  .chartsContainer {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      
    }
  }
  
  .chartBar, .chartCircle {
    width: 20%;
    margin: 20px 0;

  
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .chartTitle { 
    font-size: .9rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  .date-filters {
    gap: 20px;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .date-filters .react-datepicker-wrapper {
    width: 150px; 
    margin: 3px;
  }
  
  .date-filters .react-datepicker__input-container input {
    width: 100%; 
    padding: 10px; 
    border: 1px solid #ccc; 
    border-radius: 5px;
  }

  .periodTitle {
    font-size: 1.5rem;
  }

  .navbar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }
  
  .navbar button {
    background-color: #f8f9fa; 
    border: 2px solid #dee2e6;
    padding: 10px 20px;
    margin: 0 10px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: #e2e6ea;
    }
    
    &.active {
      background-color: #505154;
      color: #fff;
      border-bottom: 4px solid black;
    }
  }
  
  .incidentsTab__table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
  }
  
  .incidentsTab__th, .incidentsTab__td {
    border: 1px solid #cccccc;
    padding: 8px;
    text-align: left;
  }
  
  .incidentsTab__tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .incidentsTab__title {
    margin-bottom: 16px;
    font-size: 24px;
  }

  .incidentsTab__th {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 1rem;
    text-align: center;
    background-color: #495057;
    color: white;
  }

  .incidentsTab__td {
    font-size: 0.9rem;
    text-align: center;
  }
  .incidentsTab__tr:hover {
    background-color: #ddd;
  }

  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.pagination-btn {
    padding: 0px 20px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    background-color: #ccc;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    outline: none;
    transition: opacity 0.3s ease, background-color 0.3s ease;
}

.pagination-btn:hover {
    background-color: #505154
}

.pagination-btn:active {
    background-color: #505154;
}

.pagination-btn.disabled-btn {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination-btn.active-btn {
    background-color: #505154;
}


  #dark {
    .managementData {
      background-color: #2C2C2C;
      color: white; 
  
      .managementDataTitle {
        color: white; 
      }
  
      .dashboardMD .card {
        border-color: rgba(255, 255, 255, 0.2); 
        color: white;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      }
  
      .date-filters .react-datepicker-wrapper input {
        border-color: #666;
      }
  
      .navbar button {
        background-color: #444;
        border-color: #666;
        color: white;
  
        &:hover {
          background-color: #555;
        }
  
        &.active {
          border-bottom-color: white;
        }
      }
  
      .incidentsTab__th {
        background-color: #444;
      }
  
      .incidentsTab__tr:nth-child(even) {
        background-color: #3C3C3C;
      }
  
      .incidentsTab__tr:hover {
        background-color: #555;
      }
    }

    .filter__incidents {
      background-color: #555;
      color: white;

      &:hover {
          background-color: #777;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .facilityIncidents {
      display: none !important;
    }
    .hide-on-mobile {
      display: none !important;
    }
  }

  .chartBarReview {
    width: 60%;
    height: 100%;
  }