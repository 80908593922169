
.articles-search {
    align-items: center;
    padding: 1rem;
    margin: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    z-index: 4;
  }

  .articles-input {
    padding: 10px;
    font-size: 1.1rem;
    width: 300px;
    border: #feba00 2px solid;
    border-radius: 8px;  
    color: white;
    z-index: 4;
    background-color: #181818;
  }

  #light .articles-input {
    background-color: white;
    color: black;
    border: black 2px solid;
  }

  .articles-input::placeholder {
    color: white;
    font-size: 1rem;
    text-align: center;
  }
  #light .articles-input::placeholder {
    color: black;
  }
.table-wrapper{
    margin: 10px 20px 5px;
    box-shadow: 0px 35px 50px rgba( 0, 0, 0, 0.2 );
}

.userGrid {
    position: relative;
}
.fl-table {
    font-size: 20px;
    font-weight: normal;
    padding: 10px;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: #181818;
    color: #FFF;
    border: 2px solid #181818;
}
#light .fl-table {
    background-color: white;
    color: black;
}
.fl-table td, .fl-table th {
    text-align: center;
    padding: 8px;
}

.userGrid-body {
    cursor: pointer;
    background-color: white(222, 219, 219, 0.982);
}

.userGrid-body:hover {
    background-color: #f5f5f5;
}
.fl-table td {
    border-right: 1px solid #f8f8f8;
    font-size: 16px;
}

.fl-table thead th {
    color: #ffffff;
    background: black;
}


.fl-table thead th:nth-child(odd) {
    color: #ffffff;
    background: black;
}

.fl-table tr:nth-child(even) {
    background: #F8F8F8;
}

.table-container {
    max-width: 100%;
    width: 100%;
  }

.select-wrapper {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    margin : auto;
}

.select_user_grid {
    background-color: white ;
    color: black;
    font-size: 1rem; 
    padding: 12px;
    border: 1px solid black;
    border-radius: 8px;
    padding: 8px;
    text-align: center;
    cursor: pointer;
  }
  #dark .select_user_grid {
    background-color: #181818;
    color: white;
  }

  #dark .userGrid-body {
    background-color: #181818;
    color: white;
}
  
  option {
    background-color: white;
    color: #424C7C;
    font-size: 1rem;
    padding: 12px;
  }
  
  select:focus {
    outline: none;
    box-shadow: 0 0 0 2px white;
  }
  .userGrid-footer {
    padding: 1rem;
    text-align: center;
    color: white;
    font-size: 1rem; 
    background-color: #181818;
}

#light .userGrid-footer {
    color: black; 
    background-color: white;
}

.button-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    margin: auto;
    width: 90%;
    padding: 1rem;
}

.more-results-btn {
  background-color: white;
  color: black;
  font-size: 1rem;
  padding: 12px;
  border: 1px solid black;
  border-radius: 8px;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  margin: auto;
}

#dark .more-results-btn {
  background-color: #181818;
  color: white;
}