.proceduresManagement { 
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
    font-size: 0.9rem;
}

#light {
    .proceduresManagement {
      background-color: #fff; // Fond clair
      color: #000; // Texte de couleur noire

      .table {
        border-collapse: collapse;
        width: 100%;
        color: #000;
        
        .table-row {
          border-bottom: 1px solid #ccc; // Bordure plus claire
          &:hover {
            background-color: #eee; // Couleur de survol claire
          }
        }
  
        .table__header, .table__cell {
          padding: 8px;
          text-align: center;
        }
  
        .actions .delete-button {
          background-color: #e74c3c;
          color: #fff;
          border: none;
          padding: 8px 16px;
          cursor: pointer;
          transition: background-color 0.3s;
          &:hover {
            background-color: #c0392b;
          }
        }
      }
  
      .regulation__header__button {
        .AddTransport {
          background-color: #555;
          color: #fff;
          border: none;
          padding: 8px 16px;
          cursor: pointer;
          transition: background-color 0.3s;
          &:hover {
            background-color: #777;
          }
        }
      }
    }
  }

#dark {
    .proceduresManagement {
      background-color: #2C2C2C; // Fond sombre
      color: white;
  
      .table {
        border-collapse: collapse;
        width: 100%;
        color: white;
        
        .table-row {
          border-bottom: 1px solid #666; // Bordure plus sombre
          &:hover {
            background-color: #3C3C3C; // Couleur de survol sombre
          }
        }
  
        .table__header, .table__cell {
          padding: 8px;
          text-align: center;
        }
  
        .actions .delete-button {
          background-color: #e74c3c;
          color: white;
          border: none;
          padding: 8px 16px;
          cursor: pointer;
          transition: background-color 0.3s;
          &:hover {
            background-color: #c0392b;
          }
        }
      }
  
      .regulation__header__button {
        .AddTransport {
          background-color: #555;
          color: white;
          border: none;
          padding: 8px 16px;
          cursor: pointer;
          transition: background-color 0.3s;
          &:hover {
            background-color: #777;
          }
        }
      }
    }
  }

  .procedures-modal {
    background-color: #f3f3f3;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    min-height: 100vh;
  
    .procedure-title-input {
        font-size: 1rem;
        font-weight: bold;
        border: none;
        border-bottom: 2px solid #ccc;
        margin-bottom: 20px;
        width: 100%;
        padding: 5px 0;
    
        &:focus {
          outline: none;
          border-bottom-color: #007bff;
        }
      }
    .form-control,
    .form-select {
      width: 100%;
      padding: 10px;
      margin: 10px 0;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }
  
    .form-group {
      margin-bottom: 20px;
    }
  
    .form-label {
      display: block;
      margin-bottom: 5px;
      color: #333;
        font-size: 1rem;
    }
  
    .form-checkbox {
      margin-left: 10px;
    }
  
    .add-field-button,
    .submit-btn,
    .add-field-btn {
      background-color: grey;
      color: white;
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover, &:focus {
        background-color: #2C2C2C;
      }
    }
  
    .fields-list {
      margin-top: 20px;
  
      .section-title {
        margin-bottom: 10px;
        color: #333;
      }
      }
    }
      

    .form-control,
    .form-select {
      width: 100%;
      padding: 10px;
      margin: 10px 0;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }
  
    .form-group {
      margin-bottom: 20px;
    }
  
    .form-label {
      display: block;
      margin-bottom: 5px;
      color: #333;
        font-size: 1rem;
    }

    .field-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      border: 1px solid #ddd;
      margin-bottom: 5px;
      background-color: #fff;
      font-size: 1rem;
    }
    
    .field-item.chapter {
      background-color: grey;
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .8rem;
    }
    
    .field-item.info {
      background-color: #2196f3;
      font-size: .8rem;
    }
    
    .field-icon {
      margin-right: 10px; /* espace entre l'icône et le texte */
      font-size: 1rem;
    }
    

    .proceduresView {
      background: #f5f5f5;
      border-radius: 8px;
      padding: 20px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      font-family: 'Arial', sans-serif;
    
      .identifierProcedures{
        font-size: 1rem;
        display: flex;
        justify-content: space-between;
      }
      h2 {
        color: #333;
        font-size: 1.1rem;
        margin: 1rem 0;
        text-align: center;
      }
    
      h3 {
        background-color: grey;
        color: #fff;
        padding: 10px;
        border-radius: 4px;
        margin-bottom: 20px;
      }
    
      p {
        margin-bottom: 15px;
        color: #666;
        font-size: 1rem;
        overflow-wrap: break-word;
        max-width: 1000%; 
      }
    
      .MuiFormControl-root {
        width: 100%;
        margin-bottom: 20px;
      }
    
      .MuiInput-underline:before {
        border-bottom-color: white;
      }
    
      .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom-color: white;
      }
    
      .MuiInputBase-root {
        background: #fff;
        border-radius: 4px;
      }
    
      .MuiButtonBase-root {
        margin: 10px 0;
      }
    
      .MuiToggleButtonGroup-root {
        display: flex;
        justify-content: space-between;
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
    
        .MuiToggleButton-root {
          flex-grow: 1;
          margin: 0;
          border: none;
          color: white;
    
          &.Mui-selected {
            border: 1px solid white;

          }
        }
      }
    
      .file-input {
        border: 2px dashed white;
        padding: 15px;
        text-align: center;
        border-radius: 4px;
        margin-bottom: 20px;
        background: #fff;
    
        input {
          display: none;
        }
    
        label {
          display: inline-block;
          padding: 10px 20px;
          background-image: linear-gradient(to right, white 30%, rgb(120, 119, 119) 80%);
          color: #fff;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    
      .checkbox-group, .radio-group {
        background: #fff;
        padding: 15px;
        border-radius: 4px;
      }
    
      .MuiFormGroup-root {
        flex-direction: row;
        justify-content: space-between;
      }
    
        .MuiCheckbox-root, .MuiRadio-root {
          color: white;
        }
    
      button[type="submit"] {
        width: 100%;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        color: #fff;
        background-image: linear-gradient(to right, white 30%, rgb(120, 119, 119) 80%);
        margin-top: 20px;
        cursor: pointer;
        &:hover {
          background-image: linear-gradient(to right, white 30%, rgb(120, 119, 119) 80%);
        }
      }
    
      .form-actions {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    
        .button {
          padding: 10px 20px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-weight: bold;
          color: #fff;
    
          &.cancel {
            background-color: #f44336;
    
            &:hover {
              background-color: #e53935;
            }
          }
    
          &.save {
            background-color: #2196f3;
    
            &:hover {
              background-color: #1e88e5;
            }
          }
        }
      }
    }
    
    .actionsProcedures {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;
    }
/* Dans votre fichier SCSS */

/* Dans votre fichier SCSS */

.procedure-responses {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;

  div {
    margin-bottom: 20px;

    h2 {
      color: #333;
      font-size: 24px;
      margin-bottom: 10px;
    }

    p {
      overflow-wrap: break-word; /* Alternative moderne à word-wrap pour une meilleure compatibilité */
      max-width: 50%; 
      margin-bottom: 15px;
      color: #666;
    }

    form {
      .MuiFormControl-root {
        width: 100%;
        margin-bottom: 20px;
      }

      .MuiInputLabel-root {
        color: white;
      }

      .MuiInput-underline:before {
        border-bottom-color: white;
      }

      .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom-color: white;
      }

      .MuiInputBase-root {
        background: #fff;
        border-radius: 4px;
      }

      .MuiButtonBase-root {
        margin: 10px 0;
      }

      .MuiToggleButtonGroup-root {
        display: flex;
        justify-content: space-between;
        background: #fff;
        border-radius: 4px;
        overflow: hidden;

        .MuiToggleButton-root {
          flex-grow: 1;
          margin: 0;
          border: none;
          color: white;

          &.Mui-selected {
            color: #fff;
          }
        }
      }

      .file-input {
        border: 2px dashed white;
        padding: 15px;
        text-align: center;
        border-radius: 4px;
        margin-bottom: 20px;
        background: #fff;

        input {
          display: none;
        }

        label {
          display: inline-block;
          padding: 10px 20px;
          background-image: linear-gradient(to right, white 30%, rgb(120, 119, 119) 80%);
          color: #fff;
          border-radius: 4px;
          cursor: pointer;
        }
      }

      .checkbox-group, .radio-group {
        background: #fff;
        padding: 15px;
        border-radius: 4px;
      }

      .MuiFormGroup-root {
        flex-direction: row;
        justify-content: space-between;
      }

      .MuiFormControlLabel-root {
        margin-left: 0;
        margin-right: 0;

        .MuiCheckbox-root, .MuiRadio-root {
          color: white;
        }
      }

      button[type="submit"] {
        width: 100%;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        color: #fff;
        background-image: linear-gradient(to right, white 30%, rgb(120, 119, 119) 80%);
        margin-top: 20px;
        cursor: pointer;

        &:hover {
          background-image: linear-gradient(to right, rgb(52, 52, 52) 30%, #99610c 80%);
        }
      }
    }
  }
}

.GroupResponses {
  margin-bottom: 20px;
}

.buttonLogin {
    width: 100%;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    color: #fff;
    background-color: #2C2C2C;
    margin-top: 20px;
    cursor: pointer;
    &:hover {
      background-image: linear-gradient(to right, rgb(52, 52, 52) 30%, #99610c 80%);
    }
}

.modal-content-mobile {
  margin: auto;
  width: 100%; // Utilisez 100% de la largeur pour les petits écrans
  height: 90%; // Hauteur ajustée pour les mobiles
  padding: 10px;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  color: #000;
}

@media (min-width: 768px) {
  .modal-content-mobile {
    width: 30%; // Largeur pour les écrans plus grands
    height: 100%;
  }
}

.close-modal-button {
  display: none; // Masquer par défaut
  display: block; // Afficher sur les écrans mobiles
  width: 100%; // Largeur complète


  @media (max-width: 767px) { // Pour les écrans mobiles
    display: block; // Afficher sur les écrans mobiles
    width: 100%; // Largeur complète
    padding: 10px;
    margin-top: 20px;
    background-color: white; // Couleur de fond
    color: white; // Couleur du texte
    border: none;
    border-radius: 4px;
    font-size: 16px;
  }
}
.discreet-button {
  background-color: transparent;
  color: '#515151'; /* Couleur du texte */
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 0.8rem; /* Ajustez la taille de la police si nécessaire */
}
