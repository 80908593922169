:root {
  /* Variables pour le thème clair (light) */
  --menu-background-color: #ffffff;
  --menu-text-color: #1a2b42;
  --menu-hover-background-color: rgba(0, 0, 0, 0.05);
  --menu-active-background-color: #f0f0f0;
}

#dark {
  /* Variables pour le thème sombre (dark) */
  --menu-background-color: #181818;
  --menu-text-color: #ffffff;
  --menu-hover-background-color: rgba(255, 255, 255, 0.1);
  --menu-active-background-color: #333333;
}

/* --------- STYLES DU MENU --------- */
.menu {
  display: flex;
  flex-direction: column;
  background-color: var(--menu-background-color);
  color: var(--menu-text-color);
  width: 4rem;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  align-items: center;
  overflow: hidden; /* pour masquer le dépassement */
  justify-content: center; /* centre les éléments si place dispo */

  &__top,
  &__bottom {
    flex-shrink: 0;
  }

  &__top {
    position: absolute;
    top: 1rem;
  }

  &__bottom {
    position: absolute;
    bottom: 1rem;
  }

  &__links {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 0;
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.1rem;
    margin-top: 1rem;
    &__img {
      display: block;
      margin: auto;
      max-width: 50%;
      height: auto;
    }
  }

  &__link {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 0;
    font-size: 0.9rem;
    color: inherit;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: var(--menu-hover-background-color);
    }

    &.active {
      background-color: var(--menu-active-background-color);
    }

    svg {
      /* Icones du menu principal (déjà paramétrées en 'fontSize="small"') */
      /* On peut encore ajuster ici si besoin */
    }
  }

  &__socials {
    display: flex;
    margin-top: 1rem;

    a {
      margin: 0 0.3rem;
      color: inherit;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: #b47f31;
      }
    }
  }
}

/* Bouton éventuel pour le changement de thème (la brightButton) */
.brightButton {
  display: flex;
  justify-content: center;
}

/* --------- STYLES DU SOUS-MENU (ACCORDÉON) --------- */
.submenu {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--menu-background-color);
  padding-left: 1rem; /* Décalage pour le sous-niveau */

  li {
    list-style: none;
    margin: 0;
    padding: 0;

    /* Liens du sous-menu */
    .menu__link {
      font-size: 0.8rem; /* un peu plus petit pour le texte du sous-menu */
      padding: 0.5rem 0.5rem;
      text-decoration: none;

      &:hover {
        background-color: var(--menu-hover-background-color);
      }

      &.active {
        background-color: var(--menu-active-background-color);
      }

      svg {
        /* On laisse la taille inline (dans Menu.js) prendre le dessus */
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .menu {
    width: 4rem !important;
  }
}

.main-content {
  margin-left: 240px; /* Ajustez selon la largeur finale du menu si vous déployez un menu plus large */
  padding: 1rem;
}
