.taskManagement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 20px;
  background-color: #f5f5f5;
  
  .column {
    width: 32%;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    overflow: scroll;
    max-height: 90vh;

    h2 {
      background-color: #2c3e50;
      color: #ffffff;
      text-align: center;
      padding: 15px 0;
      margin: 0;
      font-size: 1.5rem;
    }

    &-content {
      padding: 20px;
    }
  }

  .task {
    background-color: #e8e8e8;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    transition: background-color 0.3s;

    &:hover {
      background-color: #dcdcdc;
    }

    span {
      display: flex;
      flex-direction: column;

      strong {
        font-size: 1rem;
        color: #333;
      }
    }

    button {
      background-color: #c0392b;
      border: none;
      border-radius: 5px;
      color: #ffffff;
      padding: 8px 15px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #a93226;
      }
    }
  }
}

.task-planner {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
  
  .column {
    width: 32%;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    h2 {
      background-color: #505154;
      color: #ffffff;
      text-align: center;
      padding: 15px 0;
      margin: 0;
      font-size: 1.2rem;
    }

    &-content {
      padding: 20px;
    }
  }

  .task {
    background-color: #e8e8e8;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    transition: background-color 0.3s;

    &:hover {
      background-color: #dcdcdc;
    }

    span {
      display: flex;
      flex-direction: column;

      strong {
        font-size: 1rem;
        color: #333;
      }
    }

    button {
      background-color: #c0392b;
      border: none;
      border-radius: 5px;
      color: #ffffff;
      padding: 8px 15px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #a93226;
      }
    }
  }
}

.task-card {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out;

  &.low {
    background-color: #d4edda; // Vert pastel
  }

  &.medium {
    background-color: #fff3cd; // Orange pastel
  }

  &.high {
    background-color: #f8d7da; // Rouge pastel
  }

  .priority-indicators {
    display: flex;
    align-items: center;
    margin-top: 10px;

    button {
      border: none;
      cursor: pointer;
      width: 5px;
      height: 5px;
      margin: 0 5px;
      outline: none;

      &:hover {
        opacity: 0.8;
      }
    }

    .priority-forecast {
      background-color: #819cc7; // bleu
    }
    .priority-low {
      background-color: #81c784; // Vert plus saturé
    }

    .priority-medium {
      background-color: #ffb74d; // Orange plus saturé
    }

    .priority-high {
      background-color: #e57373; // Rouge plus saturé
    }
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }

  .task-card-content {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }

  .task-card.archived {
    background-color: #e0e0e0;
  }

  .task-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    
    .task-title {
      font-size: 1rem;
      color: #2c3e50;
      margin-bottom: 5px;
    }

    .task-info {
      font-size: 0.7rem;
      color: #7f8c8d;
    }
    .task-comment {
      font-size: 0.8rem;
      color: #515a5a;
      overflow-wrap: break-word; 
      max-width: 100%; 
    }
    
  }

  .delete-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #e74c3c;
    cursor: pointer;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: #c0392b;
    }
  }
}

.form-control {
  margin-bottom: 1rem;
}

.form-control label {
  margin-bottom: 0.5rem;
  font-weight: lighter;
  font-size: 1rem;
}

.form-control select {
  width: 100%;
  padding: 0.5rem;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.actions {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}