/* Conteneur global du calendrier */
.rbc-calendar {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

/* En-tête du calendrier (barre de navigation) */
.rbc-toolbar {
  padding: 10px;
  background-color: var(--toolbar-background);
  border-bottom: 1px solid var(--border-color);
}

.rbc-toolbar .rbc-toolbar-label {
  font-size: 20px;
  font-weight: 600;
}

.rbc-toolbar button {
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: #fff;
  transition: background-color 0.3s;
}

.rbc-toolbar button:hover {
  background-color: var(--primary-color-dark);
}

.rbc-toolbar button:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--primary-color-light);
}

/* En-têtes des jours de la semaine */
.rbc-header {
  padding: 10px 0;
  background-color: var(--header-background);
  border-bottom: 1px solid var(--border-color);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

/* Style des heures à gauche */
.rbc-timeslot-group {
  border-bottom: 1px solid var(--border-color-light);
}

.rbc-time-content {
  border-top: none;
}

.rbc-time-slot {
  height: 20px;
  font-size: 12px;
  color: var(--text-color-secondary);
}

/* Style des événements */
.rbc-event {
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 12px;
  box-shadow: none;
  overflow: hidden;
}

/* Assurer que le contenu des événements ne dépasse pas */
.rbc-event-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Empêcher les événements de dépasser la hauteur de la cellule */
.rbc-row-segment {
  max-height: 20px;
}
/* Surbrillance des créneaux horaires sélectionnés */
.rbc-selected-cell {
  background-color: var(--selected-cell-background);
}

/* Style des créneaux horaires lors de la sélection */
.rbc-slot-selection {
  background-color: var(--slot-selection-background);
}

/* Aujourd'hui */
.rbc-today {
  background-color: var(--today-background);
}

/* Surbrillance des jours du week-end */
.rbc-day-bg:nth-of-type(7n+1), /* Dimanche */
.rbc-day-bg:nth-of-type(7n) { /* Samedi */
  background-color: var(--weekend-background);
}

/* Tooltip personnalisé */
.tooltip-event {
  position: relative;
}

.tooltip-event::after {
  content: attr(data-tip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #424242;
  color: #fff;
  padding: 6px 8px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 12px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
  z-index: 100;
}

.tooltip-event:hover::after {
  opacity: 1;
}

/* Styles pour la vue Mois */
.rbc-month-view {
  border: none;
}

.rbc-month-row {
  border-bottom: 1px solid var(--border-color-light);
}

.rbc-date-cell {
  padding: 8px;
  text-align: right;
  font-size: 12px;
  color: var(--text-color-secondary);
}

.rbc-off-range {
  background-color: var(--off-range-background);
}

/* Styles pour la vue Agenda */
.rbc-agenda-view table {
  border: none;
}

.rbc-agenda-view thead {
  background-color: var(--header-background);
}

.rbc-agenda-view tbody tr:nth-child(even) {
  background-color: var(--agenda-row-background);
}

.rbc-agenda-view tbody tr:hover {
  background-color: var(--agenda-row-hover);
}

/* Styles pour les événements All-Day */
.rbc-allday-cell {
  background-color: var(--allday-background);
}

/* Styles pour les boutons de navigation du calendrier */
.rbc-btn-group > button:not(:last-child) {
  margin-right: 5px;
}

.rbc-show-more {
  background-color: var(--primary-color);
  color: #fff;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
}

/* Focus sur les éléments interactifs */
button:focus,
.rbc-event:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--primary-color-light);
}

/* Variables de thème clair */
#light {
  --background-color: #ffffff;
  --text-color: #333333;
  --text-color-secondary: #757575;
  --primary-color: #1976d2;
  --primary-color-dark: #1565c0;
  --primary-color-light: rgba(25, 118, 210, 0.3);
  --toolbar-background: #ffffff;
  --header-background: #f5f5f5;
  --border-color: #dddddd;
  --border-color-light: #eeeeee;
  --selected-cell-background: rgba(25, 118, 210, 0.1);
  --slot-selection-background: rgba(25, 118, 210, 0.2);
  --today-background: #e3f2fd;
  --weekend-background: #fafafa;
  --off-range-background: #f5f5f5;
  --agenda-row-background: #fafafa;
  --agenda-row-hover: #f0f0f0;
  --allday-background: #f5f5f5;
}

/* Variables de thème sombre */
#dark {
  --background-color: #121212;
  --text-color: #e0e0e0;
  --text-color-secondary: #b0b0b0;
  --primary-color: #90caf9;
  --primary-color-dark: #42a5f5;
  --primary-color-light: rgba(144, 202, 249, 0.3);
  --toolbar-background: #1e1e1e;
  --header-background: #1e1e1e;
  --border-color: #333333;
  --border-color-light: #424242;
  --selected-cell-background: rgba(144, 202, 249, 0.1);
  --slot-selection-background: rgba(144, 202, 249, 0.2);
  --today-background: #263238;
  --weekend-background: #1e1e1e;
  --off-range-background: #1e1e1e;
  --agenda-row-background: #2e2e2e;
  --agenda-row-hover: #3e3e3e;
  --allday-background: #2e2e2e;
}

/* Adaptation du calendrier en fonction du thème */
#dark .rbc-calendar {
  background-color: var(--background-color);
  color: var(--text-color);
}

#dark .rbc-toolbar,
#dark .rbc-header,
#dark .rbc-agenda-view thead {
  background-color: var(--toolbar-background);
  color: var(--text-color);
}

#dark .rbc-timeslot-group {
  border-bottom: 1px solid var(--border-color);
}

#dark .rbc-time-slot {
  color: var(--text-color-secondary);
}

#dark .rbc-event {
  background-color: var(--primary-color-dark);
  color: #e0e0e0;
}

#dark .rbc-today {
  background-color: var(--today-background);
}

#dark .rbc-show-more {
  background-color: var(--primary-color);
  color: #e0e0e0;
}

#dark .rbc-agenda-view tbody tr:nth-child(even) {
  background-color: var(--agenda-row-background);
}

#dark .rbc-agenda-view tbody tr:hover {
  background-color: var(--agenda-row-hover);
}

/* Responsivité */
@media (max-width: 600px) {
  .rbc-toolbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .rbc-toolbar .rbc-btn-group {
    margin-top: 10px;
  }
}
