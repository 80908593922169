.crew-time-management {
  padding: 16px;
  margin: auto;
  width: 100%;
  min-height: 100vh;
  background-color: white;
}

.crew-time-management.dark {
  background-color: #2C2C2C;
  color: white;
}

.export__to__excel__button {
  margin-bottom: 16px;
  width: 40%;
}

/* Style for the unified search bar */
.search_bar {
  margin: 16px 0;
  display: flex;
  justify-content: center;

  .search-input {
    width: 100%;
    max-width: 400px; /* Adjust the width as needed */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}

.crew-sendButtons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.staffRefus {
  display: flex;
  flex-direction: column;
  color: red;
  font-size: 0.7rem;
  font-style: italic ;
}

.pause-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

h1 {
text-align: center;
margin-bottom: 16px;
}

.regulation__header__date {
display: flex;
align-items: center;
color: black;
}

.full-date {
  margin-right: 16px;
}

#dark .regulation__header__date{
color: white;
}

.date_regulation__arrow__left,
.date_regulation__arrow__right {
cursor: pointer;
}

.date_regulation__input {
padding: 8px;
font-size: 16px;
border: none;
border-radius: 4px;
background-color: #ffffff;
color: #333;
}

.date_regulation__input.dark {
background-color: #555;
color: #f5f5f5;
border: 1px solid #f5f5f5;
}

.crew-card {
background-color: #f5f5f5;
border-radius: 8px;
padding: 16px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.crew-card.dark {
background-color: #666;
box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
color: white;
}

.crew-card:first-child {
margin-top: 15px;
}

.crew-card:last-child {
margin-bottom: 0;
}

.staff-info-row {
display: flex;
flex-wrap: wrap;
margin-bottom: 16px;

.staff-info {
  flex: 1;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:last-child {
    margin-right: 0;
  }

  .staff-name {
    color: #666;
  }

  .staff-name.dark {
    color: #f5f5f5;
  }

  .time-input {
    width: 100%;
    margin-bottom: 8px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
  }

  .save-button {
    background-color: #4caf50;
    color: #fff;

    &:hover {
      background-color: #45a049;
    }
  }

  .delete-button {
    background-color: #f44336;
    color: #fff;

    &:hover {
      background-color: #d32f2f;
    }
  }
}
}

/* Style for the week selector and export button */
.week-selector-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.week-select {
  padding: 8px;
  margin-right: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #ffffff;
  color: #333;
  width: auto;
}

.week-select.dark {
  background-color: #555;
  color: #f5f5f5;
  border: 1px solid #f5f5f5;
}

.export-pdf-button {
  padding: 8px 16px;
  background-color: #555;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.export-pdf-button:hover {
  background-color: #45a049;
}

.export-pdf-button.dark {
  background-color: #666;
  color: #f5f5f5;
}

.export-pdf-button.dark:hover {
  background-color: #555;
}


