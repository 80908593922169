.seriesTab {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
    font-size: 0.9rem;
}

.seriesTab__table {
  width: 100%;
  border-collapse: collapse;
}

.seriesTab__th, .seriesTab__td {
  border: 1px solid #cccccc;
  padding: 8px;
  text-align: left;
}

.seriesTab__tr:nth-child(even) {
  background-color: #f9f9f9;
}

.seriesTab__title {
  margin-bottom: 16px;
  font-size: 24px;
}

.seriesTab__th {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 1rem;
  text-align: center;
  background-color: #495057;
  color: white;
}

.seriesTab__td {
  font-size: 0.9rem;
  text-align: center;
}

.seriesTab__tr:hover {
  background-color: #ddd;
}

.series_delete {
    background-color: #e74c3c; /* Une teinte rouge pour indiquer une action destructive */
    border: none;
    padding: 5px 5px;
    font-size: 0.9rem;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    outline: none;
    font-weight: 600; /* Police en gras pour attirer l'attention */
}

.series_delete:hover {
    background-color: #c0392b; /* Une teinte rouge plus sombre au survol pour une interaction dynamique */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.series_update {
    background-color: #3498db; /* Une teinte bleue indiquant une action sécurisée */
    border: none;
    padding: 5px 5px;
    font-size: 0.9rem;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    outline: none;
    font-weight: 600; /* Police en gras pour une clarté */
}

.series_update:hover {
    background-color: #2980b9; /* Une teinte bleue légèrement plus sombre au survol */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

  input, select {
    width: 200px;
    height: 40px;
    margin: 0 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    font-size: .9rem;
    color: #333;
    background-color: #f0f0f0;
    transition: all 0.3s ease-in-out;
    appearance: none; // Removes the default style of the select element in some browsers

    &:focus {
      outline: none;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }

  select {
    cursor: pointer;

    // Style for the dropdown arrow of select element
    background-image: linear-gradient(45deg, transparent 50%, #333 50%),
                      linear-gradient(135deg, #333 50%, transparent 50%),
                      linear-gradient(to right, #f0f0f0, #f0f0f0);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;

    // Style for the options
    option {
      padding: 10px;
    }
  }

  // Adding some hover effect
  input:hover, select:hover {
    background-color: #e0e0e0;
  }

  button {
    width: auto; // Adjust as needed
    height: 40px;
    margin: 0 10px;
    padding: 10px 20px; // Adjust padding to fit text content
    border: none;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #e0e0e0;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    &:active {
      background-color: #d0d0d0; // Slightly darker color for active state
    }
  }
}

#dark {
  .seriesTab {
      background-color: #2C2C2C;
      color: white; // Couleur du texte ajustée ici
  }

  .seriesTab__table {
    color: white; // Et ici

  }

  .seriesTab__tr {
    background-color: #3C3C3C;
    &:hover {
      background-color: #4C4C4C;
    }

  }

  .seriesTab__th, .seriesTab__td {
    border: 1px solid #666666;
    color: white; // Et aussi ici
    
  }


  .seriesTab__title {
    color: white; // Et ici
  }

  .series_update {
      background-color: #555;
      color: white; // Ajustement de la couleur du texte

      &:hover {
          background-color: #777;
      }
  }

  .series_delete {
      background-color: #555;
      color: white; // Ajustement de la couleur du texte

      &:hover {
          background-color: #777;
      }
  }

  .search-bar {
    input, select {
      color: white; // Ajustement de la couleur du texte
      background-color: #555;

      &:focus {
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
      }
    }

    select {
      background-image: linear-gradient(45deg, transparent 50%, white 50%), // Ici
                        linear-gradient(135deg, white 50%, transparent 50%), // Et ici
                        linear-gradient(to right, #555, #555);
    }

    input:hover, select:hover {
      background-color: #666;
    }

    button {
      color: white;
      background-color: #555;

      &:hover {
        background-color: #666;
      }

      &:focus {
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
      }

      &:active {
        background-color: #444;
      }
  }
}
}
