.App {
  background-color: #fff;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  transition: padding-left 0.3s ease-in-out;
  color: #1a2b42;
  scrollbar-width: thin;
  padding-left: 4rem;
}

.App::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  .App::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .App::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  .App::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  
#light {
    background-color: FFF(1, 0, 0, 0.445);
    color: black;
}

#dark {
    background-color: black;
    color: white;
}

.theme-toggle {
    position: absolute;
    top: 1rem;
    right: 2.5rem;
    cursor: pointer;
    z-index: 4;
}

.swal2-container {
  z-index: 2000 !important; /* Assurez-vous que ce z-index est supérieur à celui de tout autre élément de dialogue/modal */
}

.bad_role_message {
    color: red;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height:100vh;
    margin: auto;
    justify-content: center;
    text-align: center;
    font-size: 1.2rem;
}
  @media screen and (max-width: 768px) {
    .App {
        padding-left: 3rem;
    }
}