.updateTransportModal {
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1rem;
    margin: auto;
    width: 100%;
}

.headerModal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.updateModalTitle {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1rem;
    color: #181818;

    margin-bottom: 20px;
    color: #505154;
  }



.seventhLine {
    margin: 0.5rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}