/* PorteDocument.css */
.porte-document {
  background-color: #f8f9fa;
  color: #333;
  font-family: 'Open Sans', sans-serif;
  overflow-x: 'hidden',
}

#dark .porte-document {
  background-color: #333;
  color: white;
}

.porte-document .tabs button {
  width: 100%;
  padding: 10px;
  margin-right: 5px;
  border: none;
  border-bottom: 3px solid transparent;
  background-color: #e8e8e8;
  cursor: pointer;
  transition: all 0.3s ease;
}

#dark .porte-document .tabs button {
  background-color: #555;
}
.porte-document .tabs button:hover,
.porte-document .tabs button.active {
  border-bottom: 3px solid white;
  background-color: #dcdcdc;
}

.porte-document .content {
  margin-top: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

#dark .porte-document .content {
  background-color: #333;
  color: white;
}

.vehiculeLabel {
  font-size: 16px;
  font-weight: 600;
  color: #555;
}

.vehiculeButton {
  display: flex;
  align-items: center;
  gap: 10px;
}


/* Bouton personnalisé */
button {
  border: none;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  color: "white";
}

#dark button:hover {
  background-color: #555;
}
