@use  './_reset.css';
@use "vars";

body {
  font-size: 1.6rem;
  line-height: 1.5;
  background: #1d1d1d;
  display: block;
  min-width: 320px;
  color: #fff;
}


@media screen and (max-width: 768px) {
  body {
    padding: 0;
  }
  
}
