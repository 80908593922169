.staffManagement {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
    font-size: 0.9rem;
}

#dark .staffManagement {
    background-color: #2C2C2C;
    color: white;
}

.transportBody {
    cursor: pointer;
}

#dark .transportBody {
    background-color: #2C2C2C;
    color: white;
}

  .listing {
    font-size: 0.9rem;
  }

  #dark .listing {
    background-color: #2C2C2C;
    color: white;
    border-bottom: #7a7878 solid 1px;
  }

  .staff-counters {
    margin-bottom: 1rem;
    padding: 10px;
    background-color: #f2f2f2; /* Couleur de fond légère pour le compteur */
    border-radius: 8px; /* Coins arrondis */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Ombre subtile pour une profondeur */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .staff-counters div {
    margin-bottom: 10px;
    padding: 5px 0;
  }
  
  #dark .staff-counters {
    background-color: #333; /* Fond plus sombre pour le thème dark */
    color: white; /* Couleur de texte pour le thème dark */
  }
  
  @media screen and (max-width: 768px) {
    .staff-counters {
      margin: 10px;
      padding: 5px;
    }
  }
  @media screen and (max-width: 768px) {
    .actions {
      display: none;
    }
    
  }
  