.regulation {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
  font-size: 0.9rem;
  padding-top: 0.5rem;
}
#dark .regulation {
  background-color: #181818;
  color: white;
}

.regulation__header{
  display: flex;
  padding: 0.3rem;
  justify-content: space-between;
  align-items: center;
}

.regulation__header__date {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.regulation-search {
  align-items: center;
  padding: 0.5rem;
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.regulation-input {
  padding: 10px;
  font-size: 0.8rem;
  border: black 2px solid;
  border-radius: 8px;  
  color: black;
  text-align: center;
}


  .table__header {
    background-color: #f7f7f7;
    font-weight: bold;
    text-align: center;
    padding: 1rem 0;
    font-size: 1rem;
    color: black;
  }

  #dark .table__header {
    background-color: #181818;
    color: white;
  }

  .table-row {
    text-align: center;
    font-size: 0.8rem;
    border-bottom: 1px solid rgb(192, 194, 192);
  }
  
  .table-row > * {
    display: table-cell;
    vertical-align: middle;
  }
  
  .table-row:hover {
    background-color: rgb(215, 215, 205);
    cursor: pointer;
  }

  #dark .table-row:hover {
    background-color: rgba(169, 169, 169, 0.265);
    color: white;
  }

  .type {
    text-align: center;
    width: 3%;
  }
  
  .transportBody {
    background-color: #fff;
    overflow: auto;
    position: relative;
  }
  
  .table__cell {
    text-align: center;
  }
  
  .AddTransport {
      width: 100%;
      border: none;
      background: #505154;
      color: #fff;
      padding: 10px;
      font-size: 16px;
      cursor: pointer;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }

  #dark .AddTransport {
      color: white;
  }

  .AddTransport:hover {
      background: #555;
  }
  
  .TOTAL {
    background-color: #e35bf9;
  }
  .Paramédical {
    background-color: #93e65c;
    padding: 0.5rem;
  }
  .Ambulance {
    background-color: #ff6b61;
    padding: 0.5rem;

  }
  .Vsl {
    background-color: #57b4eb;
    padding: 0.5rem;

  }
  .Samu {
    background-color: #f5b840;
    padding: 0.5rem;

  }
  .Taxi {
    background-color: #33f1ff9d;
    padding: 0.5rem;

  }

  #dark .TOTAL,.AMB,.PARA,.VSL,.SAMU {
    color: #181818;
  }
  .deleteb {
    width: 5%
  }
  .transport-buttons {
    display: flex;
    flex-direction: column; /* Ajoutez cette ligne */
    justify-content: space-between;
    width: 100%;
    height: 100%; /* Assurez-vous qu'il prend toute la hauteur de la cellule */
  }
  .send-button, .delete-button {
    flex: 1;
  }
  
  .send-button {
    background-color: #7C9ACC;
    color: white;
    display: flex; 
    align-items: center;  
    gap: 0.5rem; 
    border: none;
    font-size: 0.6rem;
    cursor: pointer;
    &:hover {
      background-color: darkblue;
    }
  }
  
  .delete-button {
    background-color: #CE6A6B;
    width: 100%;
    color: white;
    display: flex;
    margin: auto;
    align-items: center;
    gap: 0.5rem; 
    font-size: 0.6rem;
    border: none;
    cursor: pointer;
    &:hover {
      background-color: darkred;
    }
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1w961hg-MuiAutocomplete-root .MuiOutlinedInput-root {
    height: 5vh;
  }

.date__regulation {
  display: flex;
  width: 100%;
  min-height: 6vh;
  font-size: 0.7rem;
  padding: 1rem;
  position: sticky;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

#dark .date__regulation {
  background-color: #181818;
  color: white;
}

.date_regulation__input {
  border: none;
  background-color: #fff;
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  margin: 0 1rem;
  text-align: center;
}

.date_regulation__arrow__left {
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

#dark .date_regulation__arrow__left {
  background-color: #181818;
  color: white;
}

.date_regulation__arrow__right {
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

#dark .date_regulation__arrow__right {
  background-color: #181818;
  color: white;
}
.transport_ca_container {
display: flex;
flex-direction: column;
align-items: center;
position: absolute;
left: 0;
}

.transport_ca {
    display: flex;
    align-items: center;
    text-align: right;
}
.hidden {
  opacity: 0 !important;
  visibility: hidden !important;
  transform: translateY(20px);
  transition: all 0.2s ease-out, visibility 0s linear 0s;
}
.transport_ca, .transport_number {
  transition: all 0.2s ease-out, visibility 0s linear 0.2s;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}


.regulationHide-button {
  background-color: #2c3e50; /* Une couleur de fond sombre */
  color: #ecf0f1; /* Une couleur de texte claire */
  border: 2px solid #ecf0f1;
  font-size: 0.7rem;
  border-radius: 5px;
  padding: 0.2rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.regulationHide-button:hover {
  background-color: #ecf0f1; /* Inverser les couleurs au survol */
  color: #2c3e50;
  border: 2px solid #2c3e50;
}
#dark .transport_ca {
    color: white;
}

.luxurious-style {
  td {

    p {
      font-size: .5rem; // Taille de police légèrement réduite
      color: #555; // Couleur de police discrète
      font-weight: 400; // Poids normal pour la police
    }
  }
}

.transport_ca_title  {
font-weight: bold;
}
.transport_number_title {
  font-weight: bold;
}
.transport_number_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
}
.transport_number{
  display: flex;
  align-items: center;
  text-align: right;
}
#dark .transport_number {
  color: white;
}
.transport_detail {
  display: flex;
  width: 9vh;
  justify-content: center;
  margin: .3rem;
  padding: .3rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
  border-radius: 0.2rem;
  cursor: pointer;
  transition: all 0.3s ease; /* Adding transition for smoother effect */
}

.transport_detail:hover {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 1); /* More pronounced hover effect */
}

.transport_detail.active {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 1); /* More pronounced active effect */
  background-color: #2c3e50; /* Une couleur de fond sombre */
  color: #FFF;
}

.ontime{
  color: white;
  font-weight: bold;
  background-color: rgb(26, 187, 26);
}
.advance{ 
  color: transparent;
}
.late-15{
  color: white;
  font-weight: bold;
  background-color: #F7BD5F; /* Jaune */
}

.late-30{
  color: white;
  font-weight: bold;
  background-color: #FEAC82; /* Orange moyen */
}

.late-60{
  color: white;
  font-weight: bold;
  background-color: #F38071; /* Orange rougeâtre */
}

.late-90{
  color: white;
  font-weight: bold;

  background-color: #F0604D; /* Rouge pastel */
}


.late-180{
    color: transparent;
}

  .useless-delay{
    color: transparent;
  }
.dayAndTime {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin: 10px;
}

#dark .regulation-input {
  color: white;
  border-color: white;
  background-color: #181818;
}

#dark .date_regulation__input {
  background-color: #181818;
  color: white;
  box-shadow: 0px 0px 10px white;
}

#dark .dayAndTime {
  color: white;
}

/* modal.scss */

.custom-modal {
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 768px) {
  .table {
    margin-top: 1.5rem;
    overflow-x: scroll;
  }
  .table__header {
    font-size: 0.5rem;
  }
  .table__cell {
    padding: 0.3rem 0.4rem;
    font-size: 0.5rem;
  }
  .regulation__header {
    display: flex;
    flex-direction: column;
  }

  .regulation__header__search-and-button {
    display: flex;
    flex-direction: column;
  }

  .regulation-search {
    margin-top: 1rem;
  }

  .regulation__header__filter {
    display: none;
  }
  #vehicleFilter {
    display: none;
  }
.regulation__header__button {
   display: none;
  }
  .vehicleFilter {
    display: none !important;
  }
  .table__header--hide-on-mobile,
  .table__cell--hide-on-mobile {
    display: none;
  }
  .dayAndTime {
    font-size: .9rem;
    margin: 10px;
  }
  .icon-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}