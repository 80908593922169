.dashboard {
    background-color: black;
    position: relative;
}

#light .dashboard {
    background-color: white;
}

.dashboard-title {
    font-size: 2rem;
    font-weight: bold;
    color: white;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 767px) {
        font-size: 6vw;
    }
}

#light .dashboard-title {
    color: black;
}