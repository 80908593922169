.tarifsManagement { 
    display: flex;
    flex-direction: column;
    margin: auto;
    min-height: 100vh;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
    font-size: 0.9rem;
    width: 100%;
}

#dark .tarifsManagement {
    background-color: #181818;
    color: white;
}
