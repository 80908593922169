.overView {
    align-items: center;
    width: 100%;
    height: auto;
    margin: auto;
    font-size: 0.8rem;
    @media (max-width: 767px) {
      min-height: 100vh;
    }
  }

  .datePickerContainer {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 2rem 0;
  }
  
  
  .App-articles {
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    padding: 2rem 0;
    margin: auto;
    @media (max-width: 767px) {
      flex-direction: column;
      margin: auto;
    }
  }
  
  /* Styles personnalisés pour les DatePicker */
  .datePickerInput {
    width: 100%;
    padding: 10px;
    background-color: #181818;
    border: 1px solid #555;
    color: #fff;
    border-radius: 4px;
  }
  
  .datePickerInput::placeholder {
    color: #aaa;
  }
  
  .datePickerCalendar {
    background-color: #181818;
    border: 1px solid #555;
    color: #fff;
  }
  
  .datePickerCalendar__header {
    background-color: #282828;
    border-bottom: 1px solid #555;
  }
  
  .datePickerCalendar__current-month,
  .datePickerCalendar-time__header,
  .datePickerCalendar-year-header {
    color: #fff;
  }
  
  .datePickerCalendar__day-name,
  .datePickerCalendar__day,
  .datePickerCalendar__time-name {
    color: #fff;
  }
  
  .datePickerCalendar__day:hover {
    background-color: #333;
  }
  
  .datePickerCalendar__day--selected,
  .datePickerCalendar__day--keyboard-selected {
    background-color: #008000;
    color: #fff;
  }
  
  .datePickerCalendar__day--today {
    border: 1px solid #fff;
  }
  
  .datePickerCalendar__navigation--previous,
  .datePickerCalendar__navigation--next {
    border: none;
  }
  
  .datePickerCalendar__navigation-icon::before,
  .datePickerCalendar__navigation-icon::after {
    border-color: #fff;
  }
  
  /* Autres styles existants */
  .App-article-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    margin: 10px;
    padding: 2rem;
    border-radius: 10px;
    color: white;
    box-shadow: 0px 0px 5px 2px white;
    cursor: pointer;
    background-color: #181818;
    @media (max-width: 767px) {
      width: 100%;
      margin: auto;
      margin-bottom: 30px;
    }
  }
  
  #light .App-article-preview {
    background-color: #fff;
    color: #1a2b42;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
  
  .App-article-preview:hover {
    transform: scale3d(1.05, 1.05, 1.05);
    transition: transform 0.2s ease-in-out;
  }
  
  .App-article-preview-title-text {
    max-width: 100%;
    font-size: 1rem;
    font-weight: 600;
    color: white;
    padding: 10px 0;
  }
  
  #light .App-article-preview-title-text {
    color: #1a2b42;
    @media (max-width: 767px) {
      font-size: 1.2rem;
    }
  }
  
  .App-article-preview-text {
    font-size: 1rem;
    margin-top: auto;
    @media (max-width: 767px) {
      font-size: 1rem;
    }
  }
  
  .Chart_container {
    @media (max-width: 767px) {
      display: none;
    }
  }
  
  .good {
    color: green;
  }
  .bad {
    color: red;
  }
  