.sales-division {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 90%;
  height: 100%;
  padding: 20px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  color: white;
  background-color: #181818;
}

#light .sales-division {
  background-color: #fff;
  color: #1a2b42;
}

.Chart { 
  width: 100%;
}

.yearDropDown {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 20%;
  padding: 10px 0;
  margin: auto;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 5px;
  font-size: 1rem;
  
  @media screen and (max-width: 767px) {
      width: 100%;
  }
}

#dark .yearDropDown {
  background-color: #181818;
  color: white;
}

.article-division {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: nowrap;
  padding: 20px 0;
  width: 90%;
  margin: auto;
  text-align: center;
  background-color: #181818;
}

#light .article-division {
  background-color: #fff;
  color: #1a2b42;
}

.App-article-division-preview {
  flex-basis: calc(20% - 10px);
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 0px 0px 5px 2px white;
  cursor: pointer;
}

.App-article-division-preview:hover {
  box-shadow: 0px 0px 5px 2px white;
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
}

#light .App-article-division-preview {
  background-color: #fff;
  color: #1a2b42;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.total, .amb, .para, .samu, .vsl {
  font-size: 1rem;
  font-weight: 600;
}

.total { color: #e35bf9; }
.amb { color: #cd0000; }
.para { color: #008000; }
.samu { color: #ffaa33; }
.vsl { color: #0a47e0; }
.taxi { color: #33f1ff9d; }
.baria { color: #5327e39f; }
.psy { color: #683636e3; }

.grid-button {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.toggle-btn {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 180px;
  height: 40px;
  border-radius: 8px;
  padding: 0 16px;
  font-size: 1rem;
  background-color: white;
  color: #181818;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 5px 2px white;
}

#light .toggle-btn {
  background-color: #fff;
  color: #1a2b42;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.toggle-btn:hover {
  box-shadow: 0px 0px 5px 2px white;
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
}

.date-picker-section {
  text-align: center; 
  font-weight: 500;
  padding-top: 2vh;
}

#dark .react-datepicker {
  background-color: #242424 !important;
  color: #fff !important;
  border: none !important;
}

.react-datepicker__header {
  background-color: #181818 !important;
  border-bottom: none !important;
  color: white !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker-time__header {
  color: #fff !important;
}

#dark .react-datepicker__day,
#dark .react-datepicker__time-name {
  color: #ddd !important;
}

#dark .react-datepicker__day:hover,
#dark .react-datepicker__month-text:hover,
#dark .react-datepicker__quarter-text:hover,
#dark .react-datepicker__year-text:hover {
  background-color: #525252 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #181818 !important;
  color: #fff !important;
}

#dark .react-datepicker__day--selected,
#dark .react-datepicker__day--in-selecting-range,
#dark .react-datepicker__day--in-range {
  background-color: #feba00 !important;
  color: #fff !important;
}

#dark .react-datepicker__month--selecting-range .react-datepicker__day--in-range,
#dark .react-datepicker__month--selecting-range .react-datepicker__day--in-selecting-range {
  background-color: #434343 !important;
}

#dark .react-datepicker__input-container input {
  background-color: #333 !important;
  color: #ddd !important;
}

@media (max-width: 1024px) {
  .custom-date-range {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .sales-division {
      width: 100%;
  }

  .article-division {
      flex-direction: column;
  }

  .App-article-division-preview {
      flex-basis: 100%;
  }
  .displayListButton {
    display: none;
  }
}
