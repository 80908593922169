.vehiculeManagement {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
    font-size: 0.9rem;
}

.toggle-container {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333;
  }

  #dark .toggle-container {
    color: white;
  }
  
  .toggle-container label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .toggle-container input {
    appearance: none;
    width: 40px;
    height: 20px;
    background: rgba(222, 219, 219, 1);;
    border-radius: 20px;
    position: relative;
    margin-left: 10px;
    outline: none;
    transition: background 0.3s;
  }
  
  .toggle-container input::before {
    content: '';
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 1px;
    transition: left 0.3s;
  }
  
  .toggle-container input:checked {
    background: #4caf50;
  }
  
  .toggle-container input:checked::before {
    left: 21px;
  }
  

#dark .vehiculeManagement {
    background-color: #2C2C2C;
    color: white;
}

.print-qr-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.btn-print-qr {
  display: none;
}