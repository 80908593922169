.stockManagement { 
    display: flex;
    flex-direction: column;
    margin: auto;
    min-height: 100vh;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
    font-size: 0.9rem;
    width: 100%;
}
.imageCell {
    margin: 0 auto;
}

.lowQuantity {
    background-color: red;
  }
  
  .nearExpiration {
    background-color: orange;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 10px;
    background-color: #f0f0f0; 
    bottom: 0;
    width: 100%;
    @media screen and (max-width: 915px) {
      padding: 10px 0;
      bottom: 0;
      width: 100%;
      position: relative;
      font-size: 0.5rem;
    }
  }

  @media (min-width: 915px) {
    .qr-scanner-button,
    .qr-scanner {
      display: none;
    }
  }

  @media screen and (max-width: 915px) {

    .stock {
      padding: 0;
    }
    
    .stockC {
      font-size: 0.45rem;
    }
    
    
  }
  .dateLimit {
    cursor: pointer;
  }

  .historiqueButton {
    padding: 8px 16px;
    margin: 8px;
    background-color: #4CAF50; /* or any color of your choice */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .historiqueButton:hover {
    background-color: #45a049; /* or any hover color of your choice */
  }

  .modalTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #000;
  }

  // Base overlay styles
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

.quantity-update-modal {
  &.ReactModal__Content {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 30vw;
    height: 60vh;
    transform: translate(-50%, -50%);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    overflow: auto;


    .dark-theme & {
      background-color: #2C2C2C;
      color: #FFF;
    }

    .light-theme & {
      background-color: #FAFAFA;
      color: #333;
    }

    @media (max-width: 768px) {
      width: 90vw;
      height: 100vh;
      left: 55%;
    }
  }
}

.category-modal {
  &.ReactModal__Content {
    position: fixed;
    top: 50%;
    left: 55%;
    width: 30%;
    height: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    background-color: white;
    overflow: scroll;
    z-index: 10000;


    .dark-theme & {
      background-color: grey;
      color: black;
    }

    .light-theme & {
      background-color: #fff;
      color: #000;
    }

    @media (max-width: 768px) {
      width: 90%;
      height: 60%;
    }
  }
}

.article-modal {
  &.ReactModal__Content {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 30vw;
    height: 90vh;
    transform: translate(-50%, -50%);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    background-color: white;
    flex-direction: column;
    overflow: auto;
    z-index: 10000;

    @media (max-width: 768px) {
      width: 90vw;
      height: 100vh;
      background-color: white;
      left: 55%;
    }
  }
}

#dark .historique {
  background-color: #2C2C2C;
  color: #FFF;
}

#dark .stockManagement, #dark .footer {
  background-color: #2C2C2C;
  color: #FFF;
}