.category-list {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    margin: auto;
  
    .category-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f0f0f0;
      padding: 10px 0;
      &:last-child {
        border-bottom: none;
      }
  
      .category-name {
        font-size: 16px;
        font-weight: 600;
        color: #333;
      }
  
      button {
        cursor: pointer;
        padding: 8px 12px;
        border: none;
        border-radius: 4px;
        font-size: 14px;
        transition: background-color 0.3s ease;
        
        &.series_update {
          background-color: #2ecc71; // Change to the color you want for the update button
          color: white;
          &:hover {
            background-color: darken(#2ecc71, 10%);
          }
        }
  
        &.delete-button {
          background-color: #e74c3c; // Change to the color you want for the delete button
          color: white;
          &:hover {
            background-color: darken(#e74c3c, 10%);
          }
        }
      }
    }
  }
  