/* Définition des couleurs pour chaque statut */
:root {
  --status-color-R: #28a745;   /* Vert */
  --status-color-CP: #dc3545;  /* Rouge */
  --status-color-AM: #6c757d;  /* Gris */
  --status-color-AT: #17a2b8;  /* Teal */
  --status-color-RCR: #ffc107; /* Jaune */
  --status-color-F: #fd7e14;   /* Orange */
  --status-color-SS: #6f42c1;  /* Violet */
  --status-color-ABS: #e83e8c; /* Rose */
  --status-color-EC: #20c997;  /* Cyan */
  --status-color-PAR: #343a40; /* Sombre */
  --status-color-CEX: #e0a800; /* Doré */
}

.annualPlanning {
  position: relative;
  background-color: white;
  width: 100%;
  height: 100vh;
  font-size: 0.8rem;
  overflow-x: auto;
  user-select: none;

  &__table {
    border-collapse: collapse;
    width: 100%;
  }

  &__thead {
    background-color: #f2f2f2;
    font-size: 0.8rem;
    font-weight: bold;
    position: sticky;
    top: 2rem;
    z-index: 100;
  }

  &__th,
  &__td {
    border: 1px solid #ddd;
    padding: 0.5rem;
    text-align: center;
    white-space: nowrap;
    cursor: crosshair;
  }

  &__td--name {
    cursor: grab;
  }

  &__tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  &__th {
    background-color: #181818;
    color: white;
  }

  &__td--sticky {
    position: sticky;
    left: 0;
    z-index: 100;
    background-color: white;
  }

  &__td--count {
    background-color: #e0e0e0;
    font-weight: bold;
    color: #333;
  }

  &__controls {
    display: flex;
    margin-bottom: 1rem;
  }

  &__control {
    margin-right: 0.5rem;
    padding: 0.25rem 0.5rem;
    background-color: #eee;
    border: 1px solid #ccc;
    cursor: pointer;
  }

  &__control--selected {
    background-color: #ccc;
  }

  /* Styles spécifiques pour chaque statut dans le tableau */
  &__td--T {
    background-color: var(--status-color-T);
    color: #000;
  }

  &__td--R {
    background-color: var(--status-color-R);
    color: #fff;
  }

  &__td--CP {
    background-color: var(--status-color-CP);
    color: #fff;
  }

  &__td--AM {
    background-color: var(--status-color-AM);
    color: #fff;
  }

  &__td--AT {
    background-color: var(--status-color-AT);
    color: #fff;
  }

  &__td--RCR {
    background-color: var(--status-color-RCR);
    color: #000;
  }

  &__td--F {
    background-color: var(--status-color-F);
    color: #fff;
  }

  &__td--SS {
    background-color: var(--status-color-SS);
    color: #fff;
  }

  &__td--ABS {
    background-color: var(--status-color-ABS);
    color: #fff;
  }

  &__td--EC {
    background-color: var(--status-color-EC);
    color: #fff;
  }

  &__td--PAR {
    background-color: var(--status-color-PAR);
    color: #fff;
  }

  &__td--CEX {
    background-color: var(--status-color-CEX);
    color: #000;
  }

  &__td--selected {
    border: 2px solid red;
  }

  /* Barre de sélection des statuts */
  &__statusButtons {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    gap: 0.5rem;
    padding: 0.5rem;
  }

  &__statusButton {
    flex: 1 1 calc(20% - 1rem);
    margin: 0.25rem;
    padding: 0.5rem;
    background-color: #f5f5f5;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    font-size: 0.9rem;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    color: #fff;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    }

    &.selected {
      border: 2px solid #007bff;
      box-shadow: 0 4px 10px rgba(0, 123, 255, 0.25);
    }
  }

  /* Styles spécifiques pour chaque statut dans les boutons */
  &__statusButton--R {
    background-color: var(--status-color-R);
  }

  &__statusButton--CP {
    background-color: var(--status-color-CP);
  }

  &__statusButton--T {
    background-color: var(--status-color-T);
    color: #000;
  }

  &__statusButton--AM {
    background-color: var(--status-color-AM);
  }

  &__statusButton--AT {
    background-color: var(--status-color-AT);
  }

  &__statusButton--RCR {
    background-color: var(--status-color-RCR);
    color: #000;
  }

  &__statusButton--F {
    background-color: var(--status-color-F);
  }

  &__statusButton--SS {
    background-color: var(--status-color-SS);
  }

  &__statusButton--ABS {
    background-color: var(--status-color-ABS);
  }

  &__statusButton--EC {
    background-color: var(--status-color-EC);
  }

  &__statusButton--PAR {
    background-color: var(--status-color-PAR);
  }

  &__statusButton--CEX {
    background-color: var(--status-color-CEX);
    color: #000;
  }

  &__th--weekend-holiday {
    background-color: rosybrown;
  }

  &__th {
    padding: 0.5rem;
  }

  .planningCounter {
    background-color: #f2f2f2;
    padding: 0.5rem;
    border: 5px #333;
    margin-right: 1rem;
    font-size: 1rem;
    cursor: default;
  }

  .button_toggle_planning {
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    font-size: 13px;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);

    &:hover {
      background-color: white;
      color: #181818;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    }

    &:active {
      background-color: white;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    }

    &:focus {
      outline: none;
    }
  }

  /* Mode sombre */
  #dark {
    .annualPlanning {
      background-color: #2c2c2c;

      &__thead {
        background-color: #1c1c1c;
        position: sticky;
        left: 0;
        z-index: 100;
      }
      &__tr:hover {
        background-color: #37474f; /* Couleur de surbrillance pour le mode sombre */
      }

      &__th,
      &__td {
        border-color: #555;
        color: #ccc;
      }

      &__tr:nth-child(even) {
        background-color: #1c1c1c;
      }

      &__td--sticky {
        background-color: #2c2c2c;
      }

      &__td--count {
        background-color: #333;
        color: #ccc;
      }

      &__control {
        background-color: #444;
        border-color: #555;

        &--selected {
          background-color: #555;
        }
      }

      /* Styles pour les statuts en mode sombre */
      &__td--T {
        background-color: #444;
        color: #fff;
      }

      &__td--R {
        background-color: #295429;
        color: #fff;
      }

      &__td--CP {
        background-color: #5c0000;
        color: #fff;
      }

      &__td--AM {
        background-color: #3d1f00;
        color: #fff;
      }

      &__td--RCR {
        background-color: #00005c;
        color: #fff;
      }

      &__td--F {
        background-color: #5c3d00;
        color: #fff;
      }

      &__td--AT {
        background-color: #181818;
        color: #fff;
      }

      &__td--SS {
        background-color: #5c5c00;
        color: #fff;
      }

      &__td--ABS {
        background-color: #5c005c;
        color: #fff;
      }

      &__td--EC {
        background-color: #003d3d;
        color: #fff;
      }

      &__td--PAR {
        background-color: #444;
        color: #fff;
      }

      &__td--CEX {
        background-color: #ffb24e;
        color: #000;
      }

      /* Boutons de statut en mode sombre */
      &__statusButton {
        background-color: #444;
        border-color: #555;
        color: #ccc;

        &:hover {
          opacity: 0.8;
        }

        &--T {
          background-color: #444;
          color: #fff;
        }

        &--R {
          background-color: #295429;
          color: #fff;
        }

        &--CP {
          background-color: #5c0000;
          color: #fff;
        }

        &--AM {
          background-color: #3d1f00;
          color: #fff;
        }

        &--RCR {
          background-color: #00005c;
          color: #fff;
        }

        &--F {
          background-color: #5c3d00;
          color: #fff;
        }

        &--AT {
          background-color: #181818;
          color: #fff;
        }

        &--SS {
          background-color: #5c5c00;
          color: #fff;
        }

        &--ABS {
          background-color: #5c005c;
          color: #fff;
        }

        &--EC {
          background-color: #003d3d;
          color: #fff;
        }

        &--PAR {
          background-color: #444;
          color: #fff;
        }

        &--CEX {
          background-color: #ffb24e;
          color: #000;
        }
      }

      &__statusButton.selected {
        border-color: #ccc;
        box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.3);
      }

      &__th--weekend-holiday {
        background-color: #633;
      }

      .planningCounter {
        background-color: #333;
        color: #ccc;
      }

      .button_toggle_planning {
        background-color: #444;

        &:hover,
        &:active {
          background-color: white;
          color: #181818;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  /* Réactivité pour les petits écrans */
  @media screen and (max-width: 768px) {
    &__controls {
      flex-direction: column;
      align-items: center;
    }

    &__control {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }

    &__statusButtons {
      justify-content: flex-start;
    }

    &__statusButton {
      flex: 1 1 calc(50% - 1rem); /* Deux colonnes sur mobile */
      font-size: 0.8rem;
    }

    .search-bar {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;
      gap: 0.2rem;
    }
  }
}

.annualPlanning__tr:hover {
  background-color: #e0f7fa; /* Couleur de surbrillance lors du survol */
}