.ambysoft-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 9999;
  }

  #dark .ambysoft-loader-container {
    background-color: #000000;
  }
  
  .ambysoft-loader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
  }
  
  .ambysoft-loader-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #4f5155;
    animation: ambysoft-loader-bounce 1.5s infinite;
  }

  #dark .ambysoft-loader-circle {
    background-color: #b47f31;
  }
  
  .ambysoft-loader-circle:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .ambysoft-loader-circle:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  @keyframes ambysoft-loader-bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }


  
  