// AdminDashboard.module.scss

.admin_dashboard {
  font-family: Arial, sans-serif;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column  ;
}

#dark .admin_dashboard {
  background-color: #181818;
  color: white;
}

.delete_row {
  width: 10%;
}

.row__select {
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
}

#dark .row__select {
  background-color: #181818;
  color: white;
}  

@media screen and (max-width: 768px) {
  .row__select {
    font-size: 0.85rem;
  }
}


    .updatePassword {
      border: 1px solid #c0c0c0;
      width: 30%;
      border-radius: 4px;
      padding: 8px 12px;
      font-size: 14px;
      margin-right: 8px;
      transition: border-color 0.3s, box-shadow 0.3s;

      &:focus {
        border-color: #c0c0c0;
        box-shadow: 0 0 3px #fff(0, 145, 255, 0.5);
      }
    }
    #dark .updatePassword {
      background-color: #181818;
      color: white;
    }

    .buttonUpdate {
      background-color: #c0c0c0;
      color: #fff;
      border: 1px solid #c0c0c0;
      border-radius: 4px;
      padding: 8px 16px;
      font-size: 14px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #000;
      }

      &:disabled {
        background-color: #c0c0c0;
        cursor: not-allowed;
      }
      #dark & {
        background-color: #181818;
        color: white;
        box-shadow: 0 0 3px #fff(0, 145, 255, 0.5);
      }
      #dark &:hover {
        background-color: white;
        color: #181818;
      }
    }
