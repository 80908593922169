  .error {
    color: red;
    font-size: 0.8rem;
    margin-top: 0.5rem;
    display: block;
  }

  .addTransportModal {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
  }
  
  .addModalTitle {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1rem;
    color: #181818;

    margin-bottom: 20px;
    color: #505154;
  }

  .firstLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 1rem;
  }

  .secondLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
    margin-top: 1rem;
  }

  .transport-details {
    margin-top: 1rem;
    display: grid;
    align-items: center;
    width: 100%;
    grid-template-columns: 1fr 1fr; /* Deux colonnes de largeur égale */
    gap: 16px; /* Espacement entre les colonnes */
  }

  .departure {
    display: flex;
   flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 1rem;
    padding: 0.5rem;
  }

  .arrival {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
    gap: 1rem;
  }

  .thirdLine {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem;
  }

  .thirdLineReturn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
  }

  .fourthLine {
    margin-top: 1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .fifthLine {
    margin-top: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.5rem 0;
    gap: 1rem;
  }

  .sixLine {
    margin-top: 1rem;
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }

.sevenLine {
  margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2rem;
  }
  .span_adress {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5rem 0;
  }